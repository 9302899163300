import { MutableRefObject, useEffect, useState } from 'react';

const NOTES_STARTING_COUNT = 10000;
const NOTES_END_FALLBACK = 110764;

interface Props {
  notesRef: MutableRefObject<HTMLElement | null>;
}
const useMeaningfulGroupGifts = ({ notesRef }: Props)=> {
  const [notesNumber, setNotesNumber] = useState<string>(NOTES_STARTING_COUNT.toLocaleString('en-US'));
  const [notesInViewport, setNotesInViewport] = useState<boolean>(false);
  const [totalNotes, setTotalNotes] = useState<number>(0);

  useEffect(() => {
    const event = () => {
      if (notesRef.current) {
        const { top } = notesRef.current.getBoundingClientRect();
        setNotesInViewport(top - window.innerHeight < 0);
      }
    };

    window.addEventListener('scroll', event);

    return () => {
      window.removeEventListener('scroll', event);
    };
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (totalNotes > 0 && !interval) {
      const step = Math.floor(totalNotes / 500);
      let current = NOTES_STARTING_COUNT;

      interval = setInterval(() => {
        current += step;

        if (current > totalNotes && interval) {
          setNotesNumber(totalNotes.toLocaleString('en-US'));
          clearInterval(interval);
          return;
        }

        setNotesNumber(current.toLocaleString('en-US'));
      }, 15);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [totalNotes, notesInViewport]);

  useEffect(() => {
    async function fetchTotalNotesAPI() {
      try {
        let response = await fetch('https://web.illumenotes.com/api/resources/notes'); // it blocked by cors if run in local
        const resultData = await response.json();
        const { total: updatedTotalNotes } = resultData;
        setTotalNotes(updatedTotalNotes);
      } catch (error) {
        console.error(error);
        setTotalNotes(NOTES_END_FALLBACK);
      }
    }

    fetchTotalNotesAPI();
  }, []);

  return {
    numberOfNotes: notesNumber,
  };
}

export default useMeaningfulGroupGifts;